
.featuresFirstSectionBannerPartStartHere {
    padding: 7% 0 9% 0;
}

.featuresPageImage img {
    width: 555px;
    height: 555px;
}

.featuresPageLastSection {
    padding: 2% 0;
}

.featuresPageHeadingAndIcon {
    padding: 5% 0 0 0;
}

.featuresLastSectionHeading {
    padding: 0 7%;
}

.featuresLastSectionHeading h2 {
    font-weight: 500;
    font-size: 35px;
    line-height: 65px;
    color: #FFFFFF;
    margin-bottom: 25px;
}

.featuresLastSectionHeading a {
    text-decoration: none;
}

.featuresLastSectionHeading p {
    background: #EFF3FF;
    border-radius: 17px;
    padding: 1% 5%;
    font-weight: 600;
    font-size: 20px;
    line-height: 39px;
    letter-spacing: 0.01em;
    color: #101722;
}

.featuresLastSectionHeading span img {
    position: relative;
    float: right;
    width: 29px;
    top: 5px;
}

.featuresPageHeadingAndIcon td img {
    margin-top: 7px;
}

.featuresPageHeadingAndIcon .groupsParagraph h6 {
    font-weight: 500;
    font-size: 23px;
    line-height: 35px;
    color: #FFFFFF;
}

.featuresPageHeadingAndIcon .groupsParagraph p {
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #E7E7E7;
}

.featuresPageHeadingAndIcon .groupsParagraph p span {
    color: #E71A2D;
    font-size: 20px;
    font-weight: 600;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 65px;
    }
    .featuresPageHeadingAndIcon td .clickIconBelowImage {
        width: 59px;
    }
}
@media only screen and (max-width: 1100px) {
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 65px;
    }
}
@media only screen and (max-width: 1000px) {
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 61px;
    }
    .featuresPageHeadingAndIcon td .clickIconBelowImage {
        width: 55px;
    }
}
@media only screen and (max-width: 991px) {
    .featuresPageImage img {
        width: 455px;
        height: 455px;
    }
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 111px;
    }
    .featuresPageHeadingAndIcon td .clickIconBelowImage {
        width: 125px;
    }
}
@media only screen and (max-width: 767px) {
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 39px;
    }
    .featuresPageHeadingAndIcon td .clickIconBelowImage {
        width: 33px;
    }
    .featuresPageHeadingAndIcon {
        padding: 0% 0 0 0;
    }
    .featuresLastSectionHeading h2 {
        font-size: 27px;
        margin-bottom: 25px;
        margin-top: 25px;
    }
    .featuresLastSectionHeading p {
        padding: 2.5% 5%;
    }
    .featuresPageImage {
        margin-bottom: 2.3rem;
    }
    .featuresPageImage img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .featuresPageHeadingAndIcon {
        padding: 0 5%;
    }
}
@media only screen and (max-width: 575px) {
    .featuresLastSectionHeading h2 {
        font-size: 27px;
        margin-bottom: 5px;
    }
    .featuresPageImage img {
        margin-bottom: 7%;
    }
}
@media only screen and (max-width: 425px) {
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 59px;
    }
    .featuresPageHeadingAndIcon td .clickIconBelowImage {
        width: 55px;
    }
}
@media only screen and (max-width: 375px) {
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 73px;
    }
    .featuresPageHeadingAndIcon td .clickIconBelowImage {
        width: 73px;
    }
}
@media only screen and (max-width: 320px) {
    .featuresPageHeadingAndIcon td .clickIconUpperImage {
        width: 113px;
    }
    .featuresPageHeadingAndIcon td .clickIconBelowImage {
        width: 123px;
    }
}