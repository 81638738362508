
.downloadAndInstallationPageStartHere {
    background-color: #EFF3FF;
}

.downloadAndInstallationPageContent {
    padding: 1% 0 3% 0;
}

.downloadAndInstallationPageContent h1 {
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    color: #000000;
}

.downloadAndInstallationPageContent h2 {
    font-weight: 700;
    font-size: 23px;
    line-height: 33px;
    color: #000000;
    margin-top: 35px;
}

.downloadAndInstallationPageContent p {
    font-weight: 500;
    font-size: 18px;
    line-height: 41px;
    text-align: justify;
    color: #000000;
}