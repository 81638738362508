
.downloadPageFirstSectionStartHere {
    padding: 5% 0 3% 0;
}

.downloadPageHeading {
    padding-top: 5%;
}

.downloadPageHeading h4, .downloadPageHeading h2 {
    text-align: center;
}

.downloadPageHeading h4 {
    font-weight: 500;
    font-size: 29px;
    line-height: 38px;
    color: #FFFFFF;
}

.downloadPageHeading h2 {
    font-weight: 500;
    font-size: 35px;
    line-height: 53px;
    color: #FFFFFF;
}

.downloadPageHeading p {
    text-align: center;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.downloadPageHeading p span {
    border-bottom: 2px solid #E71A2D;
}

.downloadPageHeading p span a {
    text-decoration: none;
    color: #FFFFFF;
}

.secondSectionIosCard, .secondSectionAndroidCard {
    background: #EAEEFF;
    border-radius: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.secondSectionIosCard img, .secondSectionAndroidCard img {
    cursor: pointer;
}

.secondSectionIosCard p, .secondSectionAndroidCard p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 45px 0 25px 0;
}

.secondSectionIosCard h5, .secondSectionAndroidCard h5 {
    font-weight: 500;
    font-size: 37px;
    line-height: 53px;
    color: #000000;
    margin: 11px 0 35px 0;
}

.secondSectionIosCard img {
    position: relative;
    bottom: -11px;
}

.downloadPageAndroidButton, .downloadPageIosButton {
    padding: 4%;
}

.downloadPageAndroidButton img, .downloadPageIosButton img {
    width: 225px;
}

.downloadPageAndroidButton, .downloadPageIosButton {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.downloadPageAndroidButton button, .downloadPageIosButton button {
    border: none;
    background: transparent;
}

.firstSectionCardDiv .imgSpan img {
    width: 41px;
}

.firstSectionCardDiv p {
    font-weight: 500;
    font-size: 19px;
    line-height: 38px;
    color: #FFFFFF;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 991px) {
    .secondSectionIosCard, .secondSectionAndroidCard {
        border-radius: 15px;
    }
    .secondSectionIosCard img {
        bottom: -5px;
    }
}
@media only screen and (max-width: 767px) {
    .secondSectionIosCard, .secondSectionAndroidCard {
        border-radius: 42px;
    }
    .firstSectionCardDiv p {
        margin: 19px 0;
    }
}
@media only screen and (max-width: 575px) {
    .downloadPageFirstSectionStartHere {
        display: none;
    }
    .downloadPageSecondSectionStartHere {
        padding-top: 2rem;
    }
}
@media only screen and (max-width: 425px) {
    .downloadPageHeading h4 {
        font-size: 25px;
    }
    .downloadPageHeading h2 {
        font-size: 31px;
    }
}
