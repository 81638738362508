
.nexus-upper-footer {
    padding: 4% 0 0 0;
    background: #343748;
}

.upperFooterNavbarLinksDiv ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0% 0 0 0;
}

.upperFooterNavbarLinksDiv ul li {
    margin: 0 29px;
}

.upperFooterNavbarLinksDiv ul li a {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 19px;
    line-height: 38px;
}

.upperFooterImageDiv img {
    width: 118px;
    height: 54px;
    margin-top: -11px;
}

.download-button-div {
    position: relative;
}

.download-button-div a {
    text-decoration: none;
}

.download-button-div button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: 2px solid #FFFFFF;
    border-radius: 51px;
    padding: 2% 9%;
    background: #FFFFFF;
    color: #E71A2D;
    font-weight: 500;
    font-size: 21px;
    font-family: 'Poppins', sans-serif;
    position: relative;
    left: 31%;
}

.navbar-menu-div ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
    position: absolute;
    right: 111px;
}

.navbar-menu-div ul li {
    margin: 0px 15px;
}

.navbar-menu-div ul a  {
    text-decoration: none;
    color: #282A3A;
}

.nexus-lower-footer {
    padding: 4% 0 1% 0;
    background: #343748;
}

.socia-media-icon ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 11px;
}

.socia-media-icon ul li {
    margin: 0 17px;
    width: 35px;
    height: 35px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
}

.socia-media-icon ul li:hover {
    background-color: #000000;
}

.socia-media-icon ul li i {
    text-decoration: none;
    color: #FFFFFF;
    position: relative;
    top: 2%;
    left: 23%;
    transform: translate(-50%, -50%);
    font-size: 18px;
}

.terms-and-conditions a {
    text-decoration: none;
}

.terms-and-conditions p {
    position: absolute;
    right: 121px;
}

.terms-and-conditions p, .rights-and-reserved p {
    font-weight: 300;
    font-size: 18px;
    line-height: 38px;
    color: #282A3A;
}

.privacyPolicyAndTermsButtonDiv {
    padding-top: 2%;
}

.privacyPolicyAndTermsButtonDiv p {
    text-align: center;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 19px;
    line-height: 38px;
}

.privacyPolicyAndTermsButtonDiv p a {
    text-decoration: none;
    color: #FFFFFF;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1300px) {
    .download-button-div button {
        left: 25%;
    }
}
@media only screen and (max-width: 1250px) {
    .download-button-div button {
        left: 20%;
    }
}
@media only screen and (max-width: 1200px) {
    .download-button-div button {
        left: 15%;
    }
    .socia-media-icon ul li a {
        left: 19%;
    }
}
@media only screen and (max-width: 1199px) {
    .socia-media-icon ul li {
        margin: 0 15px;
    }
    .download-button-div button {
        left: 31%;
    }
}
@media only screen and (max-width: 1150px) {
    .download-button-div button {
        left: 25%;
    }
}
@media only screen and (max-width: 1100px) {
    .download-button-div button {
        left: 20%;
    }
}
@media only screen and (max-width: 1050px) {
    .download-button-div button {
        left: 13%;
    }
}
@media only screen and (max-width: 1000px) {
    .download-button-div button {
        left: 5%;
    }
}
@media only screen and (max-width: 991px) {
    .download-button-div button {
        left: 49%;
    }
    .download-button-div button {
        padding: 4% 11%;
        font-size: 19px;
    }
    .socia-media-icon ul li {
        margin: 0 5px;
    }
    .socia-media-icon ul li a {
        top: 0%;
        left: 21%;
    }
}
@media only screen and (max-width: 950px) {
    .download-button-div button {
        left: 25%;
    }
}
@media only screen and (max-width: 900px) {
    .download-button-div button {
        left: 15%;
    }
}
@media only screen and (max-width: 800px) {
    .navbar-menu-div ul {
        right: 75px;
    }
    .terms-and-conditions p {
        right: 88px;
    }
}
@media only screen and (max-width: 767px) {
    .upperFooterNavbarLinksDiv ul li {
        margin: 0 8px;
    }
    .secondSectionIosCard, .secondSectionAndroidCard {
        margin-bottom: 33px !important;
    }
    .secondSectionIosCard img {
        bottom: 0px !important;
    }
    .downloadPageAndroidButton img {
        margin-bottom: 29px;
    }
    .download-button-div button {
        left: 0;
    }
    .navbar-menu-div ul {
        left: 55px;
    }
    .rights-and-reserved p {
        display: flex;
        justify-content: center;
        margin: auto;
        align-items: center;
        margin-top: 29px;
        margin-bottom: 21px;
    }
    .socia-media-icon ul {
        margin-bottom: 22px;
    }
    .socia-media-icon ul li {
        margin: 0 15px;
    }
    .upperFooterImageDiv img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-bottom: 35px;
    }
    .terms-and-conditions p {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        position: relative;
        left: 14px;
        margin-top: 17px;
        margin-bottom: 15px;
    }
    .download-button-div button {
        padding: 2% 7%;
        font-size: 19px;
    }
    .downloadPageSecondSectionStartHere {
        padding: 0 7%;
    }
}
@media only screen and (max-width: 375px) {
    .secondSectionIosCard, .secondSectionAndroidCard {
        border-radius: 5px;
    }
}