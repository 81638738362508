
.blogIndividualPageSectionStartHere {
    padding: 0% 21% 0% 21%;
}

.blogsNumberOneArticleSection {
    background: #EAEEFF;
    border-radius: 0 0 10px 10px;
    padding: 7% 5%;
}

.blogsNumberOneArticleSection h1 {
    font-weight: 600;
    font-size: 33px;
    line-height: 38px;
    color: #000000;
    text-align: center;
    margin-bottom: 5%;
}

.blogsNumberOneArticleSection h2 {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    color: #000000;
}

.blogsNumberOneArticleSection h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: #000000;
}

.blogsNumberOneArticleSection p, .blogsNumberOneArticleSection ul li {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-align: justify;
    color: #000000;
}