
.nexusPrivacyPolicyPageStartHere {
    background-color: #EFF3FF;
}

.privacyPolicyContent {
    background-color: #FFFFFF;
    padding: 5%;
}

.privacyPolicyContent h1 {
    font-weight: 700;
    font-size: 41px;
    line-height: 65px;
    color: #000000;
    margin-bottom: 25px;
}

.privacyPolicyContent p, .privacyPolicyContent ul li {
    font-weight: 500;
    font-size: 18px;
    line-height: 41px;
    text-align: justify;
    color: #000000;
}

.privacyPolicyContent p a {
    text-decoration: none;
    color: #000000;
}

.privacyPolicyContent p a span, .privacyPolicyContent p span {
    border-bottom: 2px solid #E71A2D;
}

.privacyPolicyContent h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    color: #000000;
    margin-top: 25px;
    margin-bottom: 25px;
}