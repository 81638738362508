
.nexusContactUsPageStartHere {
    background-color: #EFF3FF;
}

.contactUsPageContent {
    /* background-color: #FFFFFF; */
    padding: 5% 3% 3% 3%;
}

.contactUsPageContent h1 {
    font-weight: 700;
    font-size: 41px;
    line-height: 65px;
    color: #000000;
    margin-bottom: 25px;
}

.contactUsPageContent p {
    font-weight: 500;
    font-size: 18px;
    line-height: 41px;
    text-align: justify;
    color: #000000;
}

.formDiv {
    padding: 0 3% 5% 3%;
}

.formDiv form label {
    font-weight: 700;
    font-size: 18px;
    line-height: 33px;
    color: #000000;
}

.emailAddressDiv, .messageDiv {
    margin-top: 45px;
}

.formDiv form .emailAddressInput, .formDiv form .phoneNumberInput, .formDiv form .phoneNumberCodeInput {
    background: #FFFFFF;
    border: 2px solid #71748C;
    border-radius: 11px;
    padding: 3% 4%;
}

.formDiv form .phoneNumberCodeInput {
    width: 75px;
}

.formDiv form .phoneNumberInput {
    width: 305px;
}

.formDiv form .messageInput {
    background: #FFFFFF;
    border: 2px solid #71748C;
    border-radius: 11px;
}

.formDiv .sendQueryBtn {
    margin-top: 25px;
    background: #343748;
    border-radius: 284px;
    font-weight: 500;
    font-size: 25px;
    line-height: 52px;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 3% 17%;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
    .formDiv form .phoneNumberInput {
        width: 241px;
    }
}
@media only screen and (max-width: 991px) {
    .formDiv form .phoneNumberCodeInput {
        width: 57px;
    }
    .formDiv form .phoneNumberInput {
        width: 191px;
    }
    .ms-4 {
        margin-left: 0.5rem !important;
    }
    .formDiv .sendQueryBtn {
        font-size: 20px;
        padding: 2% 17%;
    }
}
@media only screen and (max-width: 767px) {
    .formDiv form .phoneNumberCodeInput {
        width: 75px;
    }
    .formDiv form .phoneNumberInput {
        width: 423px;
    }
}
@media only screen and (max-width: 575px) {
    .formDiv form .phoneNumberInput {
        width: 455px;
    }
    .formDiv .sendQueryBtn {
        padding: 2% 9%;
    }
}
@media only screen and (max-width: 572px) {
    .formDiv form .phoneNumberInput {
        width: 445px;
    }
}
@media only screen and (max-width: 550px) {
    .formDiv form .phoneNumberInput {
        width: 83%;
    }
}
@media only screen and (max-width: 519px) {
    .formDiv form .phoneNumberInput {
        width: 82%;
    }
}
@media only screen and (max-width: 490px) {
    .formDiv form .phoneNumberInput {
        width: 80%;
    }
}
@media only screen and (max-width: 441px) {
    .formDiv form .phoneNumberInput {
        width: 77%;
    }
}
@media only screen and (max-width: 425px) {
    .formDiv form .phoneNumberInput {
        width: 75%;
    }
    .contactUsPageContent h1 {
        font-size: 31px;
    }
    .contactUsPageContent p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 353px) {
    .formDiv form .phoneNumberInput {
        width: 72%;
    }
}
