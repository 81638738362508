.privacyPageFirstSectionStartHere {
    padding: 3% 0;
}

.privacyPageSecondSectionStartHere {
    padding: 3% 0;
}

.privacyPageThirdSectionStartHere {
    padding: 3% 0 5% 0;
}

.privacyPageImageDiv img {
    width: 455px;
    height: 455px;
}

.privacyPageParagraphDiv {
    margin-top: 101px;
}

.privacyPageParagraphDiv h5 {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    color: #FFFFFF;
    margin-bottom: 19px;
}

.privacyPageParagraphDiv p {
    font-weight: 400;
    font-size: 19px;
    line-height: 39px;
    text-align: justify;
    color: #FFFFFF;
}

.privacyPageParagraphDiv p span {
    color: #E71A2D;
    font-weight: 600;
    font-size: 22px;
}

.privacyPageLastSectionStartHere {
    background-color: #EFF3FF;
    padding-bottom: 49px;
    padding-top: 49px;
}

.cardHeadingAndButtonLeftDiv, .cardHeadingAndButtonMiddleDiv, .cardHeadingAndButtonRightDiv {
    width: 351px;
    height: 251px;
    background: #282A3A;
    border-radius: 22px;
}

.cardHeadingAndButtonLeftDiv h4, .cardHeadingAndButtonMiddleDiv h4, .cardHeadingAndButtonRightDiv h4 {
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    color: #FFFFFF;
    padding: 9%;
}

.cardHeadingAndButtonLeftDiv p, .cardHeadingAndButtonMiddleDiv p {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    padding: 15% 9% 0 9%;
}

.cardHeadingAndButtonRightDiv p {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    padding: 4.5% 9% 0 9%;
}

.cardHeadingAndButtonLeftDiv p a, .cardHeadingAndButtonMiddleDiv p a, .cardHeadingAndButtonRightDiv p a {
   text-decoration: none;
   color: #E71A2D;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
    .cardHeadingAndButtonLeftDiv, .cardHeadingAndButtonMiddleDiv, .cardHeadingAndButtonRightDiv {
        margin-bottom: 37px;
    }
    .boxAndMessageRightDiv {
        margin: 6% 0 3% 0;
    }
}
@media only screen and (max-width: 991px) {
    .privacyPageImageDiv img {
        width: 555px;
    }
}
@media only screen and (max-width: 767px) {
    .cardHeadingAndButtonLeftDiv {
        margin-top: 19px;
    }
    .privacyPageParagraphDiv {
        margin-top: 45px;
    }
    .privacyPageSecondSectionSecondInnerDiv {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
}
@media only screen and (max-width: 575px) {
    .cardHeadingAndButtonLeftDiv h4, .cardHeadingAndButtonMiddleDiv h4, .cardHeadingAndButtonRightDiv h4 {
        font-size: 20px;
    }
    .cardHeadingAndButtonLeftDiv p, .cardHeadingAndButtonMiddleDiv p, .cardHeadingAndButtonRightDiv p {
        font-size: 18px;
        padding: 15% 9% 0 9%;
    }
}