
.nexusTermsOfServicePageStartHere {
    background-color: #EFF3FF;
}

.termsOfServiceContent {
    background-color: #FFFFFF;
    padding: 5%;
}

.termsOfServiceContent h1 {
    font-weight: 700;
    font-size: 41px;
    line-height: 65px;
    color: #000000;
    margin-bottom: 25px;
}

.termsOfServiceContent p, .termsOfServiceContent ul li {
    font-weight: 500;
    font-size: 18px;
    line-height: 41px;
    text-align: justify;
    color: #000000;
}

.termsOfServiceContent p span {
    border-bottom: 2px solid #E71A2D;
}

.termsOfServiceContent h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    color: #000000;
    margin-top: 25px;
    margin-bottom: 25px;
}