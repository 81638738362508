/* First Section Of Landing Page */
.bannerImageSectionOfLandingPage {
    background-color: #343748;
    padding: 3% 0 5% 0;
}

.bannerImageSectionsHeadingImageAndButton {
    padding: 5% 0 0 0;
}

.bannerImageSectionsHeadingImageAndButton h2 {
    color: #FFFFFF;
    margin: 2% 0;
    font-weight: 700;
    font-size: 45px;
    line-height: 82px;
}

.bannerImageSectionsHeadingImageAndButton h2 span {
    color: #E71A2D;
    font-size: 55px;
}

.bannerImageSectionsHeadingImageAndButton p {
    font-weight: 300;
    font-size: 19px;
    line-height: 38px;
    color: #FFFFFF;
    margin-top: -23px;
}

p .incognitoRed {
    color: #E71A2D;
    font-weight: 600;
}

.bannerImageSectionsHeadingImageAndButton a {
    text-decoration: none;
}

.bannerImageSectionsHeadingImageAndButton h6 {
    font-weight: 500;
    font-size: 23px;
    line-height: 45px;
    color: #FFFFFF;
    margin-top: 75px;
}

.bannerImageSectionsHeadingImageAndButton .halfUnderlineDiv {
    width: 45px;
    height: 3px;
    background-color: #E71A2D;
}

.bannerImageSectionsImage img {
    width: 555px !important;
    height: 555px !important;
}

/* Second Section Of Landing Page */
.secondSectionOfLandingPageStartHere {
    padding: 3% 0;
    background-color: #EFF3FF;
}

.landingPageQuotesPara {
    padding: 2% 0;
}

.landingPageQuotesPara h3 {
    font-weight: 700;
    font-size: 27px;
    line-height: 47px;
    color: #282A3A;
    text-align: center;
}

.landingPageQuotesPara h3 span {
    font-weight: 700;
    font-size: 35px;
    color: #E71A2D;
}

.landingPageQuotesPara p {
    font-weight: 400;
    font-size: 21px;
    line-height: 39px;
    text-align: center;
    color: #282A3A;
}

/* Third Section Of Landing Page */
.thirdSectionOfLandingPageStartHere, .fourthSectionOfLandingPageStartHere {
    padding: 5% 0;
    background-color: #343748;
}

.fifthSectionOfLandingPageStartHere {
    background-color: #343748;
    padding: 5% 0 0 0;
}

.featureImageLeftSectionImage img {
    width: 555px;
    height: 555px;
}

.featureSectionRightHeadingAndPara {
    padding-top: 45px;
}

.featureSectionRightHeadingAndPara h4 {
    font-weight: 700;
    font-size: 39px;
    line-height: 59px;
    color: #282A3A;
}

.featureSectionRightHeadingAndPara p, .featureSectionRightHeadingAndPara .groupsParagraph {
    font-weight: 400;
    font-size: 19px;
    line-height: 43px;
    color: #FFFFFF;
}
.featureSectionRightHeadingAndPara table tr td {
    width: 10px !important;
}

.featureSectionRightHeadingAndPara p span img {
    width: 23px;
    height: 23px;
}

.featureSectionRightHeadingAndPara td img {
    width: 25px;
    height: 25px;
    position: relative;
    top: 9px;
}

.featureSectionRightHeadingAndPara .sideDetailPara {
    font-weight: 400;
    font-size: 19px;
    line-height: 39px;
    color: #FFFFFF;
}

.featureSectionRightHeadingAndPara a {
    text-decoration: none;
}

.featureSectionRightHeadingAndPara h6 {
    font-weight: 500;
    font-size: 19px;
    line-height: 45px;
    color: #FFFFFF;
}

.featureSectionRightHeadingAndPara .halfUnderlineDiv {
    width: 45px;
    height: 3px;
    background-color: #E71A2D;
}

.featureSectionRightHeadingAndPara h4 {
    font-weight: 700;
    font-size: 33px;
    line-height: 59px;
    color: #FFFFFF;
}

.featureSectionRightHeadingAndPara button {
    position: relative;
    left: 125px;
    background: #E71A2D;
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 1% 11%;
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    color: #FFFFFF;
    margin-top: 19px;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
    .bannerImageSectionsImage img {
        width: 455px !important;
        height: 455px !important;
    }
}
@media only screen and (max-width: 991px) {
    .featureSectionRightHeadingAndPara button {
        padding: 0.8% 13%;
        font-size: 20px;
        margin-top: 0px;
    }
    .bannerImageSectionsImage img {
        width: 405px;
        height: 405px;
    }
    .featureSectionRightHeadingAndPara {
        padding-top: 0px;
    }
}
@media only screen and (max-width: 800px) {
    .bannerImageSectionsImage img {
        width: 100%;
        height: 100%;
    }
    .featureSectionRightHeadingAndPara button {
        left: 71px;
    }
    .featureSectionRightHeadingAndPara h4 {
        font-size: 27px;
        line-height: 43px;
    }
}
@media only screen and (max-width: 767px) {
    .bannerImageSectionsImage img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    .fourthSectionOfLandingPageSecondInnerDiv {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
    .featureSectionRightHeadingAndPara {
        padding-right: 55px;
        padding-left: 55px;
    }
    .bannerImageSectionsHeadingImageAndButton h2 {
        margin: 0% 0;
        text-align: center;
    }
    .bannerImageSectionsHeadingImageAndButton p {
        text-align: center;
    }
    .bannerImageSectionsHeadingImageAndButton h6 {
        margin-top: 35px;
        text-align: center;
    }
    .bannerImageSectionsHeadingImageAndButton .halfUnderlineDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    .bannerImageSectionsHeadingImageAndButton {
        padding: 0% 0 0 0;
    }
    .featureImageLeftSectionImage img {
        width: 451px;
        height: 451px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-bottom: 5%;
    }
    .thirdSectionOfLandingPageStartHere, .fourthSectionOfLandingPageStartHere {
        padding: 3% 0;
    }
}
@media only screen and (max-width: 700px) {
    .bannerImageSectionsHeadingImageAndButton h2 {
        font-size: 35px;
    }
    .fourthSectionHeading {
        padding-right: 15%;
    }
}
@media only screen and (max-width: 650px) {
    .bannerImageSectionsHeadingImageAndButton a p {
        margin: 2% 0 0 0;
    }
}
@media only screen and (max-width: 600px) {
    .featureSectionRightHeadingAndPara button {
        left: 37px;
    }
}
@media only screen and (max-width: 575px) {
    .landingPageQuotesPara p {
        padding: 0 5%;
    }
}
@media only screen and (max-width: 462px) {
    .bannerImageSectionsHeadingImageAndButton h2 {
        font-size: 29px;
    }
    .bannerImageSectionsHeadingImageAndButton img {
        padding: 0 15%;
    }
    .featureImageLeftSectionImage img {
        width: 351px;
        height: 351px;
    }
    .featureSectionRightHeadingAndPara h4 {
        font-size: 27px;
    }
    .upperFooterNavbarLinksDiv ul {
        flex-direction: column;
    }
    .featureSectionRightHeadingAndPara p {
        font-size: 18px;
    }
    .upperFooterNavbarLinksDiv ul li {
        margin: 8px 0;
    }
}
@media only screen and (max-width: 425px) {
    .bannerImageSectionsHeadingImageAndButton h2 span {
        font-size: 37px;
    }
    .landingPageQuotesPara p {
        text-align: justify;
    }
}
@media only screen and (max-width: 400px) {
    .fourthSectionOfLandingPageFirstInnerDiv {
        padding: 5% 4%;
    }
}
@media only screen and (max-width: 375px) {
    .featureSectionRightHeadingAndPara {
        padding-right: 15px;
        padding-left: 15px;
    }
    .thirdSectionOfLandingPageStartHere {
        padding-bottom: 27px;
    }
    .bannerImageSectionsImage img {
        width: 305px !important;
        height: 305px !important;
    }
}