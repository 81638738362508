
.helpPageFirstSectonStartHere {
    background-color: #EFF3FF;
}

.helpPageFAQSection {
    box-shadow: 3px 0 0 0 rgba(99, 99, 99, 0.5);
    padding-top: 3%;
}

.helpPageArticlesHeading {
    padding-top: 5%;
    padding-bottom: 3%;
    padding-left: 5%;
    height: 100%;
}

.helpPageArticlesHeading h1 {
    font-weight: 600;
    font-size: 27px;
    margin-bottom: 21px;
    line-height: 38px;
    color: #000000;
}

.helpPageArticlesHeading h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 38px;
    margin: 29px 0;
}

.helpPageArticlesHeading h4 a {
    color: #27282B;
    text-decoration: none;
}

/* CSS of Accordion Start Here */
.accordion {
    --bs-accordion-bg: #EFF3FF;
    --bs-accordion-border-width: 0px;
    --bs-accordion-btn-icon: url("../../Images/Help_Page/Down_Arrow.png");
    --bs-accordion-btn-active-icon: url("../../Images/Help_Page/Down_Arrow.png");
}

.accordionHeader button {
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: #000000;
    outline: none;
}

.accordionHeader button:focus {
    border: none !important;
    background-color: transparent;
    color: #000000;
    box-shadow: none;
}

.helpPageFAQSection .active {
    background: rgba(177, 191, 248, 0.6);
    border-radius: 16px;
    padding: 13px 25px;
}

.accordionButton:not(.collapsed) {
    color: #000000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.accordionBody p {
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 21px;
}

.accordionBody p a {
    color: #000000;
    text-decoration: none;
}

.helpPageArticlesHeading img {
    width: 29px;
    position: absolute;
    margin-top: 23px;
    margin-left: 31px;
}

input.noSubmit {
    width: 100%;
    display: block;
    padding: 19px 70px 19px 70px;
    /* background: #FFFFFF url("../../Images/Help_Page/Vector.png") no-repeat 19px center; */
    /* background: #FFFFFF url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 19px center; */
    outline: none;
    border: 2px solid #71748C;
    border-radius: 22px;
    margin-bottom: 35px;
    font-size: 22px;
    color: #71748C;
    font-weight: 600;
  }

  input.noSubmit::placeholder {
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: #71748C;
  }

  .articleHeading {
    display: block;
  }

  .articlesHeading {
    display: none;
  }

  /* Responsive Page Start Here */
  @media only screen and (max-width:425px) {
    input.noSubmit {
        padding: 15px 55px 15px 55px;
        border-radius: 11px;
        font-size: 18px;
    }
    input.noSubmit::placeholder {
        font-size: 20px;
      }
    .helpPageArticlesHeading img {
        width: 25px;
        margin-top: 19px;
        margin-left: 23px;
    }
    .helpPageArticlesHeading h1 {
        font-size: 23px;
        margin-bottom: 17px;
    }
    .helpPageArticlesHeading h4 {
        font-size: 18px;
        margin: 25px 0;
    }
  }
 