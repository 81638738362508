
.nexus-navbar {
    background: #343748;
    /* padding: 1.3% 0 !important; */
    width: 100%;
}

.nexusNavbarStartHere {
    display: flex;
    justify-content: space-evenly;
}

.logo-section img {
    position: relative;
    left: 0px;
    transform: translateY(25%);
    cursor: pointer;
    width: 118px;
    height: 54px;
}

.navigation-section ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    float: right;
    transform: translateY(-60%);
    margin-right: 59px;
}

.navigation-section ul li {
    margin: 0 35px;
}

.navigation-section ul li a {
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
}

.navigation-section ul li .download-btn {
    position: absolute;
    top: -10px;
    border: 2px solid #FFFFFF;
    border-radius: 51px;
    outline: none;
    font-weight: 500;
    font-size: 21px;
    line-height: 38px;
    color: #E71A2D;
    background: #FFFFFF;
    padding: 0.5% 3%;
    font-family: 'Poppins', sans-serif;
}

.justify-button-section {
    display: none;
}

.download-section .downloadIcon {
    display: none;
}

/* .navigation-section ul li .active {
    border-bottom: 2px solid #E71A2D;
} */

/* Responsive Page Start Here */
@media only screen and (max-width: 1225px) {
    .navigation-section ul {
        margin-right: 99px;
    }
}
@media only screen and (max-width: 1199px) {
    .navigation-section ul li a {
        font-weight: 500;
        font-size: 18px;
    }
    .navigation-section ul {
        margin-right: 39px;
    }
    .download-section .download-btn {
        font-size: 18px;
        top: 28px;
    }
    .logo-section img {
        transform: translateY(29%);
    }
}
@media only screen and (max-width: 1100px) {
    .navigation-section ul {
        margin-right: 65px;
    }
}
@media only screen and (max-width: 1000px) {
    .navigation-section ul {
        margin-right: 127px;
    }
}
@media only screen and (max-width: 991px) {
    .logo-section img {
        transform: translateY(43%);
        width: 95px !important;
        left: -71px;
        height: 45px;
    }
    .navigation-section ul {
        margin-right: 35px;
        transform: translateY(-35%);
    }
    .navigation-section ul li {
        margin: 0 25px;
    }
    .download-section .download-btn {
        top: 22px;
    }
}
@media only screen and (max-width: 850px) {
    .download-section .download-btn {
        right: 27px;
    }
    .navigation-section ul li {
        margin: 0 20px;
    }
    .navigation-section ul {
        margin-right: 73px;
    }
    .logo-section img {
        left: -35px;
    }
}
@media only screen and (max-width: 800px) {
    .navigation-section ul li {
        margin: 0 15px;
    }
    .logo-section img {
        left: 0px;
    }
    .navigation-section ul {
        margin-right: 107px;
    }
    .download-section .download-btn {
        right: 33px;
    }
}
@media only screen and (max-width: 767px) {
    .nexusNavbarFirstInnerDiv {
        max-width: 100%;
    }
    .navigation-section ul {
        margin-right: 0px;
        align-items: flex-start;
        flex-direction: column;
        transform: translateY(13%);
        margin-bottom: 25px;
        float: none;
    }
    .nexusNavbarStartHere {
        display: inline;
    }
    .navigation-section {
        height: 0;
        overflow: hidden;
        transition: all 0.3s linear;
    }
    .show-navigation-section {
        height: 13.5rem;
    }
    .logo-section img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        transform: translateY(-15%);
    }
    .navigation-section ul li a {
        line-height: 47px;
    }
    .navigation-section .download-btn {
        display: none;
    }
    .download-section .downloadIcon {
        position: absolute;
        right: 51px;
        top: 24px;
        width: 45px;
        display: block;
    }
    .justify-button-section {
        position: absolute;
        top: 13px;
        left: 0px;
        display: block;
    }
    .justify-button-section span {
        font-size: 27px;
        margin-left: 61px;
        position: absolute;
        top: 13px;
        color: #FFFFFF;
    }
}
@media only screen and (max-width: 700px) {
    .justify-button-section span {
        margin-left: 59px;
    }
}
@media only screen and (max-width: 475px) {
    .download-section .downloadIcon {
        right: 35px;
        width: 35px;
    }
}
@media only screen and (max-width: 425px) {
    .download-section .downloadIcon {
        top: 31px;
    }
}
@media only screen and (max-width: 375px) {
    .justify-button-section span {
        font-size: 23px;
    }
    .logo-section img {
        width: 85px !important;
    }
    .download-section .downloadIcon {
        right: 29px;
        width: 31px;
        top: 33px;
    }
    .navigation-section ul {
        transform: translateY(10%);
        margin-left: -31px;
    }
    .justify-button-section span {
        margin-left: 27px;
    }
}

