
.blogPagesNumberOfBlogsSection {
    padding: 1% 7%;
}

.blogsContainer img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 425px;
    border-radius: 5px;
    padding-bottom: 6%;
}

.blogsContainer {
    padding: 3% 3%;
    background: linear-gradient(180deg, #C8D4F7 0%, #FBFCFF 100%);
    border-radius: 25px;
    margin: 5% 0;
}

.blogsContainer h1 {
    font-weight: 600;
    font-size: 27px;
    line-height: 38px;
    color: #000000;
    margin-bottom: 3%;
}

.blogsContainer .blogDescription {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 39px;
    text-align: justify;
    color: #000000;
    margin-bottom: 3%;
}

.blogsContainer p a {
    text-decoration: none;
    color: #000000;
}

.blogsContainer .readMoreLink {
    font-weight: 600;
    font-size: 18px;
    line-height: 29px; 
}

.blogsContainer .readMoreLink span {
    border-bottom: 2px solid #E71A2D;
}

.blogsContainer .readMoreLink a:hover {
    color: #E71A2D;
}

.downloadReactIconDivInBlogPage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.downloadReactIconDivInBlogPage .downloadReactIconButton {
    font-size: 41px;
    margin-top: 0;
    cursor: pointer;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 991px) {
    .blogPagesNumberOfBlogsSection {
        padding: 2% 15%;
    }
}
@media only screen and (max-width: 767px) {
    .blogsContainer .blogDescription {
        font-size: 16px;
    }
    .blogsContainer .readMoreLink {
        font-size: 16px;
    }
    .blogsContainer h1 {
        font-size: 23px;
    }
}
@media only screen and (max-width: 575px) {
    .blogPagesNumberOfBlogsSection {
        padding: 2% 5%;
    }
}